import React, { useState, useEffect } from 'react';
import Navbar from './../Navigation/Navbar';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { ArrowBack, FacebookOutlined, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { t } from 'i18next';
import { IsAndroid } from '../../Components/Shared/IsAndroid';
import { IsIos } from '../../Components/Shared/IsIos';
import { useNavigate } from 'react-router-dom';
import { useMediaPredicate } from 'react-media-hook';



export default function ArticleBetterRelationship2025() {

    const theme = useTheme();
    const navigate = useNavigate();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    const redirectToContact = () => {
        // Naviguje na hlavní stránku a nastaví hash
        navigate('/#contact');
    };

    const redirectToShop = () => {
        if (IsAndroid()) {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.Loveguard.App'; // Replace with your actual Google Play Store URL
        } else if (IsIos()) {
            // Redirect to Apple App Store if the user is on iOS
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134'; // Replace with your actual Apple App Store URL
        } else {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134?platform=iphone'; // Replace with your actual Google Play Store URL;
        }
    }

    useEffect(() => {
        // Posune stránku na začátek při každém renderování komponenty
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Stack >
                <Navbar />
                <Box sx={{ mt: '40px', padding: '2em', mb: '-60px' }}>
                    <Button startIcon={<ArrowBack />} onClick={() => { navigate('/') }}>
                        zpět
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: biggerThan700? '28em' : '2em', paddingY: '2em', gap: '20px' }}>

                    <Typography variant='h2'>
                        Jak zlepšit a oživit vztah v roce 2025
                    </Typography>
                    <Typography variant='h6' color='primary.main'>
                        <ul style={{ marginLeft: '20px' }}>
                            <li><a href="#komunikace"><Typography color='primary.main'>Komunikace a porozumění ve vztahu</Typography></a>
                                <ul style={{ marginLeft: '20px' }}>
                                    <li><a href="#otevrenost"><Typography color='primary.main'>Otevřenost a upřímnost</Typography></a></li>
                                    <li><a href="#naslouchani"><Typography color='primary.main'>Aktivní naslouchání</Typography></a></li>
                                    <li><a href="#konflikty"><Typography color='primary.main'>Řešení konfliktů bez hádek</Typography></a></li>
                                    <li><a href="#potreby"><Typography color='primary.main'>Jasná komunikace potřeb</Typography></a></li>
                                    <li><a href="#respekt"><Typography color='primary.main'>Respekt k odlišnostem</Typography></a></li>
                                </ul>
                            </li>
                            <li><a href="#spolecne"><Typography color='primary.main'>Společné trávení času: Jak posílit vaše pouto</Typography></a></li>
                            <li><a href="#intimita"><Typography color='primary.main'>Jak zlepšit intimitu, vášeň a romantiku ve vztahu</Typography></a>
                                <ul style={{ marginLeft: '20px' }}>
                                    <li><a href="#emocniIntimita"><Typography color='primary.main'>Emoční intimita</Typography></a></li>
                                    <li><a href="#malickosti"><Typography color='primary.main'>Radost z maličkostí</Typography></a></li>
                                    <li><a href="#rande"><Typography color='primary.main'>Pravidelné rande</Typography></a></li>
                                    <li><a href="#intimnikomunikace"><Typography color='primary.main'>Otevřená komunikace o intimitě</Typography></a></li>
                                    <li><a href="#ritualy"><Typography color='primary.main'>Malé rituály pro každodenní blízkost</Typography></a></li>
                                </ul>
                            </li>
                            <li><a href="#duvera"><Typography color='primary.main'>Důvěra a respekt: Základ pevného vztahu</Typography></a></li>
                            <li><a href="#rust"><Typography color='primary.main'>Společné cíle a růst</Typography></a></li>
                        </ul>
                    </Typography>
                    <Typography>
                        Každý vztah potřebuje pravidelnou péči. Ať už se potýkáte s každodenní rutinou nebo jen hledáte způsoby, jak být partnerovi blíž. Přečtěte si inspiraci a praktické kroky k vytvoření pevnějšího a šťastnějšího vztahu. Objevte nové možnosti, jak si vzájemně porozumět, podporovat se a růst.
                    </Typography>
                    <Typography id='komunikace' variant='h3' color='primary.main'>
                        1. Komunikace a porozumění ve vztahu
                    </Typography>
                    <Typography>
                        Komunikace ve vztahu je základním pilířem každého zdravého a šťastného partnerství. Bez ní se i ty nejpevnější vztahy mohou dostat do slepé uličky. Jak ale dosáhnout toho, aby si partneři opravdu rozuměli?
                    </Typography>
                    <Typography id='otevrenost' variant='h4'>
                        Otevřenost a upřímnost
                    </Typography>
                    <Typography>
                        Klíčem k lepší komunikaci je schopnost mluvit otevřeně a bez strachu z odsouzení. Sdílej své potřeby, přání i obavy tak, aby partnerka jasně věděla, co prožíváš. Ujisti se, že tvá slova jsou jasná a přímá.
                    </Typography>
                    <Typography id='naslouchani' variant='h4'>
                        Aktivní naslouchání
                    </Typography>
                    <Typography>
                        Nauč se naslouchat aktivně – dívej se partnerce do očí, přikyvuj, usměj se nebo použij gesta, která dávají najevo, že jsi zapojen do rozhovoru. Takto nejen slyšíš, co partnerka říká, ale také ji ujišťuješ, že její slova mají váhu.
                    </Typography>
                    <Typography>
                        Pro vztah je totiž zásadní jak verbální, tak neverbální komunikace. Mimika, tón hlasu, gesta nebo fyzická blízkost mohou často vypovědět více než samotné věty. Například jemný dotek nebo upřímný pohled do očí dokážou vyjádřit podporu a porozumění tam, kde slova selhávají.
                    </Typography>
                    <Typography variant='h6'>
                        Chceš s aktivním nasloucháním pomoci?
                    </Typography>
                    <Typography>
                        Stáhni si zdarma appku LOVEGUARD a objev tipy a výzvy, které zlepší konkrétně tvůj vztah.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        chci aplikaci loveguard
                    </Button>
                    <Typography id='konflikty' variant='h4'>
                        Řešení konfliktů bez hádek
                    </Typography>
                    <Typography>
                        Každý vztah čelí neshodám, ale způsob, jakým je řešíme, má zásadní vliv na jeho kvalitu. Místo výčitek používejte věty označující vaše pocity (například: <span style={{ fontStyle: 'italic' }}>„Cítím se přehlížený, když…“ nebo „Nelíbí se mi, když…“, </span>). Konflikt se tak stává příležitostí k růstu, nikoli k prohlubování propasti.
                    </Typography>
                    <Typography id='potreby' variant='h4'>
                        Jasná komunikace potřeb
                    </Typography>
                    <Typography>
                        Nečekej, že partner automaticky pochopí tvé potřeby. Nauč se je vyjadřovat srozumitelně a konkrétně. Například místo: „Chci, aby ses víc snažila," zkus říct: <span style={{ fontStyle: 'italic' }}>„Rád bych, kdybychom trávili společný čas i bez telefonu.”</span>
                    </Typography>
                    <Typography>
                        Své potřeby i partnerky zjistíš v kvízu lásky v aplikaci LoveGuard. Díky tomu budeš přesně vědět, co chcete a dostaneš kroky, jak se k tomu přiblížit.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        stáhnout aplikaci zdarma
                    </Button>
                    <Typography id='respekt' variant='h4'>
                        Respekt k odlišnostem
                    </Typography>
                    <Typography>
                        Uvědom si, že každý má svůj komunikační styl a způsoby vyjadřování emocí. Respektuj, že partnerka nemusí vždy reagovat tak, jak očekáváš. Hledejte společnou ce	stu. Pomůže Ti v tom test lásky v appce LOVEGUARD.                    </Typography>
                    <Typography id='spolecne' variant='h3' color='primary.main'>
                        2. Společné trávení času: Jak posílit vaše pouto
                    </Typography>
                    <Typography>
                        Kvalitně strávený čas ve dvou je základem silného vztahu. Zařaďte aktivity podle vašich zájmů – od romantických večeří až po dobrodružné výlety. <b>Klíčem je plánování!</b> Dohodněte si společné aktivity, které přinášejí radost vám oběma - získáte tak i další témata k rozhovoru.
                    </Typography>
                    <Typography>
                        Zkuste také <b>digitální detox</b> – odložte telefony a věnujte se jeden druhému bez rušivých vlivů. I krátký čas offline může zázračně posílit vaši blízkost.
                    </Typography>
                    <Typography>
                        Nevíš, jak na to? 💡 <b>S aplikací LOVEGUARD to bude snadné!</b>
                    </Typography>
                    <Typography>
                        Najdeš v ní tipy na společné aktivity nebo plánovač pro snadné organizování vašeho času. A co víc? Díky menstruačnímu kalendáři můžeš snadno zjistit, kdy je ideální čas na klidný večer nebo energetické dobrodružství. Poradí ti taky, co dělat a nedělat v každé fázi menstruačního cyklu, aby ses nedostal do zbytečných konfliktů.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        stáhnout loveguard
                    </Button>
                    <Typography id='intimita' variant='h3' color='primary.main'>
                        3. Jak zlepšit intimitu, vášeň a romantiku ve vztahu
                    </Typography>
                    <Typography>
                        Intimita, vášeň a romantika udržují váš vztah živý a harmonický. Přestože každodenní povinnosti mohou odvádět vaši pozornost, investice do blízkosti a romantických chvil se vždy vyplatí.
                    </Typography>
                    <Typography id='emocniIntimita' variant='h4'>
                        Emoční intimita
                    </Typography>
                    <Typography>
                        Často si intimitu spojujeme pouze s fyzickým kontaktem, ale zahrnuje mnohem víc. Je o sdílení pocitů, důvěře a schopnosti být k sobě upřímní. Každý vztah potřebuje emocionální blízkost, která je pilířem pevného partnerství. Mluvte o tom, co jste prožili, jak se cítíte, sdílejte sny a věnujte si společný čas.
                    </Typography>
                    <Typography id='malickosti' variant='h4'>
                        Radost z maličkostí
                    </Typography>
                    <Typography>
                        Romantika nemusí znamenat velká gesta. Někdy stačí maličkosti, které ukážou, že na svou partnerku myslíš. Napiš jí krátkou zprávu během dne, přines oblíbený zákusek nebo ji překvap společným výletem. Klíčem je věnovat pozornost tomu, co partnerku potěší a co jí ukáže, že ti na ní záleží.
                    </Typography>
                    <Typography>
                        Konkrétní tipy a nápady, co dělat, najdeš v appce LOVEGUARD.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        CHCI NÁPADY Z APPKY
                    </Button>
                    <Typography id='rande' variant='h4'>
                        Pravidelné rande
                    </Typography>
                    <Typography>
                        Ať už jste spolu měsíc nebo deset let, najděte si čas na pravidelné rande. Vypněte telefony a soustřeďte se pouze na sebe. To pomáhá obnovit spojení mezi partnery, ale také buduje vzpomínky, na které budete rádi vzpomínat.
                    </Typography>
                    <Typography id='intimnikomunikace' variant='h4'>
                        Otevřená komunikace o intimitě
                    </Typography>
                    <Typography>
                        Nebojte se mluvit o tom, co v oblasti intimity potřebujete a očekáváte. To, co fungovalo dříve, se může změnit. Buďte k sobě upřímní a společně hledejte způsoby, jak znovu zapálit jiskru, která vás k sobě přitáhla.
                    </Typography>
                    <Typography id='ritualy' variant='h4'>
                        Malé rituály pro každodenní blízkost
                    </Typography>
                    <Typography>
                        Začněte den společným objetím, večer si povídejte o tom, co se během dne stalo, nebo si vytvořte jiné rituály, které vás spojí. Tyto drobné zvyky pomáhají posilovat a budovat zdravý vztah a tím pádem i vášeň.
                    </Typography>
                    <Typography id='duvera' variant='h3' color='primary.main'>
                        4. Důvěra a respekt: Základ pevného vztahu
                    </Typography>
                    <Typography>
                        Budování důvěry po zklamání nebo krizi bývá náročné, ale otevřená komunikace a trpělivost dokážou zázraky. Vytvářejte prostředí, kde se oba partneři cítí <b>bezpečně, oceňovaně a respektovaně.</b>
                    </Typography>
                    <Typography>
                        Posílit vzájemný respekt znamená <b>naslouchat jeden druhému, dodržovat sliby a podporovat se navzájem.</b> Pokud vás trápí nejistoty nebo žárlivost, zaměřte se na to, co je vyvolává, a hledejte řešení společně.
                    </Typography>
                    <Typography variant='h6'>
                        💡 Jak mám poznat, co moje partnerka potřebuje?
                    </Typography>
                    <Typography>
                        Udělejte si <b>kvíz 5 jazyků lásky</b> v aplikaci LOVEGUARD a zjistěte, jak projevovat lásku tak, aby ji váš partner skutečně vnímal a oceňoval.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        Otevřít aplikaci
                    </Button>
                    <Typography id='rust' variant='h3' color='primary.main'>
                        5. Společné cíle a růst
                    </Typography>
                    <Typography>
                        Jak tvořit budoucnost ve dvou? <b>Stanovte si společné cíle</b>, které přinesou do vašeho vztahu nový směr a motivaci. Ať už jde o plánování vysněné dovolené, společné bydlení nebo založení rodiny - sdílené cíle posilují pouto a dávají vašemu vztahu nový rozměr.
                    </Typography>
                    <Typography>
                        💡 <b>Inspirace a přehled na jednom místě:</b> V aplikaci LOVEGUARD si můžete do wishlistu zapisovat svá přání a cíle. Postupně si budete odškrtávat splněné sny a zpětně uvidíte, co všechno jste spolu zažili a překonali – třeba rozjezd vlastního podnikání nebo žádost o ruku na Eiffelovce!
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        Chci si vytvořit náš WISHLIST
                    </Button>
                    <Typography>
                        Podporujte se navzájem v osobním růstu. Oslavujte každý úspěch, ať je malý či velký. Společně můžete vytvořit dlouhodobou vizi svého života, která bude odrážet vaše hodnoty.
                    </Typography>
                    <Typography>
                        LOVEGUARD vám přeje, ať se máte ve vztahu skvěle!
                    </Typography>
                    <Typography>
                        A pokud něco nejde tak, jak si přejete, pojďte se svěřit (a inspirovat se) do čistě pánské facebookové skupiny.
                    </Typography>
                    <Button variant='contained' onClick={() => { window.location.href = ('https://www.facebook.com/groups/804023694343382') }}>
                        PŘIDAT SE DO SKUPINY
                    </Button>

                </Box>

                <div id="contact" style={{ height: 'auto', width: '100%', background: '#272727', zIndex: 1000 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '2em' }}>
                        <Button target="_blank" href='https://www.facebook.com/profile.php?id=100089619233058'><FacebookOutlined></FacebookOutlined></Button>
                        <Button target="_blank" href='https://www.instagram.com/loveguard_official/'><Instagram></Instagram></Button>
                        <Button target="_blank" href='https://twitter.com/Loveguard_tweet'><Twitter></Twitter></Button>
                        <Button target="_blank" href='https://www.youtube.com/@loveguard6430'><YouTube></YouTube></Button>
                        <Button target="_blank" href='https://www.linkedin.com/company/loveguard/'><LinkedIn></LinkedIn></Button>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>{t('Contact')}</Typography>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>Jakub Forman</Typography>
                            </div>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>forman@loveguard.cloud</Typography>
                            </div>
                            <div style={{ marginTop: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('/TermsAndConditionsApp', '_blank') }}>Smluvní podmínky</Button>
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('/PrivacyPolicyApp', '_blank') }}>Zásady ochrany osobních údajů</Button>
                            </div>
                        </Box>
                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Společnost</Typography>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>LOVEGUARD s.r.o.</Typography>
                            </div>
                            <div>
                                <Typography variant='body1' marginLeft='6%' marginBottom='40px' color='white'>IČO: 21908451</Typography>
                            </div>

                        </Box>

                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Pro Média</Typography>

                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/Erro5FSWYJhCumBMFMXNhuABS0I8DMPOQAKeHCAczpqT3w?e=a9wNAM', '_blank') }}>Loga a fotky</Button>
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/EgefSryjT5JOpSEgeeobFc0BGBFn5nraZ8r47duFLtCMbQ?e=d83xZ2', '_blank') }}>Zmínky o nás</Button>
                            </div>

                        </Box>

                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Mapa Stránek</Typography>

                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/') }}>Aplikace</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/AboutUs') }}>O nás</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/#vyzvy') }}>Výzvy</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/quiz') }}>Kvíz</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { window.open('/ebook') }}>Ebook</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/#contact') }}>Kontakt</Button>
                                {/* <Button variant='text' size='small' onClick={() => {
                                    const newWindow = window.open('/'); // Otevře hlavní stránku
                                    if (newWindow) {
                                        newWindow.onload = () => {
                                            newWindow.location.hash = '#contact'; // Nastaví hash až po načtení
                                        };
                                    }
                                }}>Kontakt</Button> */}
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={redirectToShop}>Stáhnout APP</Button>
                            </div>

                        </Box>
                    </Box>
                </div>
            </Stack>
        </div>
    )
}
