import React, { useState, useEffect } from 'react';
import Navbar from './../Navigation/Navbar';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { ArrowBack, FacebookOutlined, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { t } from 'i18next';
import { IsAndroid } from '../../Components/Shared/IsAndroid';
import { IsIos } from '../../Components/Shared/IsIos';
import { useNavigate } from 'react-router-dom';
import { useMediaPredicate } from 'react-media-hook';



export default function ArticleInfidelity() {

    const theme = useTheme();
    const navigate = useNavigate();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    const redirectToContact = () => {
        // Naviguje na hlavní stránku a nastaví hash
        navigate('/#contact');
    };

    const redirectToShop = () => {
        if (IsAndroid()) {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.Loveguard.App'; // Replace with your actual Google Play Store URL
        } else if (IsIos()) {
            // Redirect to Apple App Store if the user is on iOS
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134'; // Replace with your actual Apple App Store URL
        } else {
            // Redirect to Google Play Store if the user is on Android
            window.location.href = 'https://apps.apple.com/cz/app/loveguard/id6689517134?platform=iphone'; // Replace with your actual Google Play Store URL;
        }
    }

    useEffect(() => {
        // Posune stránku na začátek při každém renderování komponenty
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Stack >
                <Navbar />
                <Box sx={{ mt: '40px', padding: '2em', mb: '-60px' }}>
                    <Button startIcon={<ArrowBack />} onClick={() => { navigate('/') }}>
                        zpět
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column',  paddingX: biggerThan700? '28em' : '2em', paddingY: '2em', gap: '20px' }}>

                    <Typography variant='h2'>
                        Nevěra ve vztahu: Jak ji pochopit a vyhnout se jí
                    </Typography>
                    <Typography variant='h6' color='primary.main'>
                        <ul style={{marginLeft: '20px'}}>
                            <li><a href="#nevera"><Typography color='primary.main'>Co je to nevěra</Typography></a></li>
                            <li><a href="#emopsycho"><Typography color='primary.main'>Emocionální a psychická nevěra</Typography></a></li>
                            <li><a href="#druhy"><Typography color='primary.main'>Druhy nevěry</Typography></a>
                                <ul style={{marginLeft: '20px'}}>
                                    <li><a href="#jednoraz"><Typography color='primary.main'>Jednorázová nevěra</Typography></a></li>
                                    <li><a href="#opakovana"><Typography color='primary.main'>Opakovaná a dlouhodobá nevěra</Typography></a></li>
                                </ul>
                                </li>
                            <li><a href="#priciny"><Typography color='primary.main'>Příčiny nevěry</Typography></a></li>
                            <li><a href="#vyhnuti"><Typography color='primary.main'>Jak se vyhnout nevěře</Typography></a></li>
                        </ul>
                    </Typography>
                    <Typography id='nevera' variant='h3' color='primary.main'>
                        Co je to nevěra
                    </Typography>
                    <Typography>
                        Nevěra je milostný poměr mimo vztah. Může mít více podob – od fyzické intimity s někým jiným až po emocionální spojení, které vytváří pouto mimo partnerství. Každý vztah má své specifické hranice, a proto je důležité otevřeně komunikovat, co pro vás nevěra znamená a jak jí předcházet.                    </Typography>
                    <Typography id='emopsycho' variant='h3' color='primary.main'>
                        Emocionální a psychická nevěra
                    </Typography>
                    <Typography>
                        <b>Psychická a emocionální nevěra</b> se liší od <b>fyzické nevěry</b> tím, že nezahrnuje tělesný kontakt, ale spíše hluboké citové spojení s někým mimo vztah. Zatímco fyzická nevěra je obvykle spojena s intimními nebo sexuálními vztahy, emocionální nevěra se odehrává v mysli a srdci.
                    </Typography>
                    <Typography>
                        Partner, který prožívá emocionální nevěru, může sdílet své nejhlubší myšlenky, pocity či intimní chvíle s někým jiným, což může výrazně narušit vztah. Emoční nevěra může způsobit pocit zrady a odcizení, protože druhý partner se často cítí odstrčený.
                    </Typography>
                    <Typography>
                        I když nemusí být vždy snadné ji odhalit, emocionální nevěra bývá signálem, že partnerství potřebuje více pozornosti, otevřenosti a práce na vzájemné důvěře.
                    </Typography>
                    <Typography>
                        Pokud se chcete vyhnout emocionální nevěře, zkuste LOVEGUARD - Aplikaci pro páry, která Ti nabídne konkrétní nástroje a techniky, jak posílit váš vztah a znovu najít cestu k sobě.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        stáhnout appku
                    </Button>
                    <Typography id='druhy' variant='h3' color='primary.main'>
                        Druhy nevěry
                    </Typography>
                    <Typography>
                        Nevěra může mít různé podoby – jednorázový úlet, který se stal v návalu emocí či slabosti, nebo dlouhodobé a opakované nevěrné chování, které ukazuje na hlubší problémy ve vztahu či osobnosti. Rozdíly mezi těmito typy jsou klíčové při řešení konfliktu.                    </Typography>
                    <Typography id='jednoraz' variant='h4'>
                        Jednorázová nevěra
                    </Typography>
                    <Typography>
                        Jednorázová nevěra často vzniká impulzivně - ve chvílích, kdy emoce přebíjí rozum. Může se odehrát na večírku pod vlivem alkoholu, při služební cestě nebo během nečekaného setkání, kdy se člověk cítí osamělý, nepochopený nebo touží po chvilce vzrušení.                    </Typography>
                    <Typography>
                        Někdy je podvod výsledkem okamžitého pokušení, jindy pramení z vnitřní bolesti nebo pocitu, že ve vztahu něco chybí. Ať už je příčinou cokoli, nevěra může zasáhnout hluboko do srdce všech zúčastněných.
                    </Typography>
                    <Typography>
                        Při řešení nevěry je důležité posoudit její závažnost a dopad na vztah. Jednorázová nevěra může být bolestivá, ale je možné hledat cestu k odpuštění pomocí otevřené komunikace, obnovením důvěry a případné odborné pomoci.
                    </Typography>
                    <Typography>
                        ➡️ Pokud však nechceš investovat spoustu peněz do terapií nebo se jednoduše bojíš svěřovat cizímu člověku a raději bys problém řešil sám s partnerkou, stáhni si appku LOVEGUARD, která ti poskytne potřebné nástroje a bude tě doprovázet na cestě při znovuobnovení důvěry.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        stáhnout appku
                    </Button>
                    <Typography id='opakovana' variant='h4'>
                        Opakovaná a dlouhodobá nevěra
                    </Typography>
                    <Typography>
                        Naopak dlouhodobá či opakovaná nevěra, někdy nazývaná jako chronická nevěra, je situace, kdy člověk vědomě a opakovaně porušuje důvěru partnera. Může jít o vytváření paralelních vztahů nebo opakované vyhledávání příležitostí k nevěře.
                    </Typography>
                    <Typography>
                        Tento typ chování často ukazuje na hlubší problémy, jako je nezralost v emocích, strach ze závazků nebo nezpracované vzorce chování z minulosti.
                    </Typography>
                    <Typography>
                        Řešení chronické nevěry obvykle vyžaduje více než jen omluvu – je často nezbytné zapojit terapeuta, který pomůže rozplést příčiny tohoto chování a zvážit, zda má vztah smysl zachraňovat. Rozhodující je ochota obou partnerů aktivně pracovat na řešení - v tom ti může pomoci naše <span style={{textTransform: 'uppercase', color: '#F34848', textDecoration: 'underline'}} onClick={() => { window.location.href= ('https://www.facebook.com/groups/804023694343382') }}>FB skupina pro muže</span>, kde se můžeš poradit s ostatními.
                    </Typography>
                    <Typography id='priciny' variant='h3' color='primary.main'>
                        Příčiny nevěry
                    </Typography>
                    <Typography>
                        Nevěra často vzniká z pocitu nenaplněných potřeb – ať už jde o nedostatek sexu, intimity nebo emocionálního spojení. Když jeden z partnerů cítí, že mu ve vztahu něco zásadního chybí, může hledat uspokojení jinde.
                    </Typography>
                    <Typography>
                        Klíčem k prevenci je uvědomit si své potřeby a naučit se je otevřeně sdílet. Upřímný rozhovor o tom, co vás trápí nebo po čem toužíte, může vytvořit prostor pro porozumění a změnu.
                    </Typography>
                    <Typography>
                        Co vlastně potřebujete si můžete ujasnit v Kvízu lásky. Je možné, že budete překvapení.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/quiz') }}>
                        udělat si kvíz lásky
                    </Button>
                    <Typography>
                        Aby se podobná situace neopakovala, je důležité:
                        <ul style={{ marginLeft: '28px' }}>
                            <li>budovat vzájemnou důvěru,</li>
                            <li>podporovat komunikaci,</li>
                            <li>pravidelně pečovat o intimitu i emocionální blízkost.</li>
                        </ul>
                    </Typography>
                    <Typography>
                        ➡️ Nevíš, jak na to? Aplikace LOVEGUARD ti pomůže se všemi body.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        stáhnout appku
                    </Button>
                    <Typography id='vyhnuti' variant='h3' color='primary.main'>
                        Jak se vyhnout nevěře
                    </Typography>
                    <Typography>
                        Nevěře se dá často předejít tím, že na vztahu pracujete a věnujete mu pozornost, kterou si zaslouží. Základem je otevřeně mluvit – říct, co potřebujete, co vám chybí, nebo co vás štve.
                    </Typography>
                    <Typography>
                        Když k sobě budete upřímní a budete se vzájemně snažit pochopit, váš vztah má šanci ustát i větší výzvy.
                    </Typography>
                    <Typography>
                        Důležité je také nepodceňovat společný čas. Najděte si společné zájmy, podnikněte něco nového, co vás oba baví, nebo si stanovte pravidelné chvíle jen pro vás dva, například společná večeře nebo procházka. Při plánování vašeho času vám pomůže LOVEGUARD.
                    </Typography>
                    <Typography>
                        Udržujte intimitu a vášeň živé – i drobná gesta, jako objetí, polibek na rozloučenou nebo malé překvapení, mohou vztah posilnit. Případně můžete udělat partnerce radost malým dárkem, který může zpříjemnit vaše společné chvíle.
                    </Typography>
                    <Typography>
                        Pokud cítíte, že se od partnerky vzdalujete, je lepší to řešit hned. LOVEGUARD vám pomůže posílit vztah – naučí vás techniky komunikace, nabídne výzvy pro zlepšení důvěry a budování intimity. Prevence nevěry začíná tam, kde oba partneři chtějí společně růst a být si oporou.
                    </Typography>
                    <Button variant='contained' onClick={() => { navigate('/appdownload') }}>
                        stáhnout appku
                    </Button>
                    <Typography variant='h6'>
                        ➡️ Chcete-li se dozvědět další tipy, koukněte na náš článek <span style={{textTransform: 'uppercase', color: '#F34848', textDecoration: 'underline'}} onClick={() => {navigate('/ArticleBuildRelationship2025')}}>Jak zlepšit a oživit vztah v roce 2025</span> a inspirujte se moderními přístupy, které mohou váš vztah posunout na novou úroveň.
                    </Typography>


                </Box>

                <div id="contact" style={{ height: 'auto', width: '100%', background: '#272727', zIndex: 1000 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '2em' }}>
                        <Button target="_blank" href='https://www.facebook.com/profile.php?id=100089619233058'><FacebookOutlined></FacebookOutlined></Button>
                        <Button target="_blank" href='https://www.instagram.com/loveguard_official/'><Instagram></Instagram></Button>
                        <Button target="_blank" href='https://twitter.com/Loveguard_tweet'><Twitter></Twitter></Button>
                        <Button target="_blank" href='https://www.youtube.com/@loveguard6430'><YouTube></YouTube></Button>
                        <Button target="_blank" href='https://www.linkedin.com/company/loveguard/'><LinkedIn></LinkedIn></Button>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>{t('Contact')}</Typography>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>Jakub Forman</Typography>
                            </div>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>forman@loveguard.cloud</Typography>
                            </div>
                            <div style={{ marginTop: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('/TermsAndConditionsApp', '_blank') }}>Smluvní podmínky</Button>
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('/PrivacyPolicyApp', '_blank') }}>Zásady ochrany osobních údajů</Button>
                            </div>
                        </Box>
                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Společnost</Typography>
                            <div>
                                <Typography variant='body1' marginLeft='6%' color='white'>LOVEGUARD s.r.o.</Typography>
                            </div>
                            <div>
                                <Typography variant='body1' marginLeft='6%' marginBottom='40px' color='white'>IČO: 21908451</Typography>
                            </div>

                        </Box>

                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Pro Média</Typography>

                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/Erro5FSWYJhCumBMFMXNhuABS0I8DMPOQAKeHCAczpqT3w?e=a9wNAM', '_blank') }}>Loga a fotky</Button>
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' onClick={() => { window.open('https://loveguard-my.sharepoint.com/:f:/g/personal/forman_loveguard_cloud/EgefSryjT5JOpSEgeeobFc0BGBFn5nraZ8r47duFLtCMbQ?e=d83xZ2', '_blank') }}>Zmínky o nás</Button>
                            </div>

                        </Box>

                        <Box minWidth='300px'>
                            <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>Mapa Stránek</Typography>

                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/') }}>Aplikace</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/AboutUs') }}>O nás</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/#vyzvy') }}>Výzvy</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/quiz') }}>Kvíz</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { window.open('/ebook') }}>Ebook</Button>
                            </div>
                            <div style={{ marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={() => { navigate('/#contact') }}>Kontakt</Button>
                                {/* <Button variant='text' size='small' onClick={() => {
                                    const newWindow = window.open('/'); // Otevře hlavní stránku
                                    if (newWindow) {
                                        newWindow.onload = () => {
                                            newWindow.location.hash = '#contact'; // Nastaví hash až po načtení
                                        };
                                    }
                                }}>Kontakt</Button> */}
                            </div>
                            <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                                <Button variant='text' size='small' sx={{ justifyContent: 'flex-start' }} onClick={redirectToShop}>Stáhnout APP</Button>
                            </div>

                        </Box>
                    </Box>
                </div>
            </Stack>
        </div>
    )
}
